import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Title, ButtonIcon, Text } from "../../components/Core";
import Availability from "../../components/Availability";
import { Link } from "gatsby";
import styled from "styled-components";

const LinkSocial = styled.a`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary} !important;
`

const Hero = () => {
  return (
    <>
      <Section>
        <Container>
          <Row className="text-center justify-content-center">
            <Col lg="10" xl="7">
              <Availability />
              <div className="text-center my-5">
                <Title>Did you also build an app? Let's talk.</Title>
              </div>
              <div className="text-center">
                <Link to={"/submission/create"}>
                  <ButtonIcon>
                    Submit Your App
                  </ButtonIcon>
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="text-center justify-content-center align-items-center mt-5">
            <Col lg="10">
              <Text variant="small" color="heading">
                Follow <LinkSocial href="https://twitter.com/techprimate">@techprimate</LinkSocial> to stay updated!
              </Text>
            </Col>
          </Row>

        </Container>
      </Section>
    </>
  );
};

export default Hero;
