import { graphql, Link, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Masonry from "react-masonry-component";
import styled from "styled-components";
import {
  Box,
  Text,
  ButtonOutline,
  ListNav, Section, Title
} from "../../components/Core";
import RotateAppIcon from "../../components/RotateAppIcon";
import { useWindowSize } from "../../hooks";
import { breakpoints } from "../../utils";


const WorkCard = styled(Box)``;
const WorkText = styled(Box)``;

const Grid = (props) => {
  const size = useWindowSize();

  const masonryOptions = {
    transitionDuration: 1000,
  };

  return size.width < breakpoints.lg ? (
    <Masonry
      options={masonryOptions}
      className={"masonry-grid row"}
      {...props}
    />
  ) : (
    <Row {...props} />
  );
};

const Apps = () => {
  const gridWorks1 = useStaticQuery(graphql`
    query HighlightsQuery {
      apps: allAirtable(
        filter: {
          table: {
            eq: "Public Apps"
          }
        }
        sort: {
          order: DESC, 
          fields: [
            data___Timestamp
          ]
        }
      ) {
        nodes {
          data {
            Name
            Creator
            Icon {
              url
              thumbnails {
                large {
                  url
                }
              }
            }
            Website
            Latest
            Highlight
            Path
          }
          recordId
        }
      }
    }`);

  const [items, setItems] = useState([]);
  const [activeLink, setActiveLink] = useState("highlights");

  const filterBy = (cat) => {
    if (cat === "*") {
      setActiveLink("*");
      setItems(gridWorks1.apps.nodes);
    } else if (cat === "highlights") {
      const filteredItems = gridWorks1.apps.nodes.filter((item) => {
        return item.data.Highlight
      });
      setActiveLink(cat);
      setItems(filteredItems);
    } else if (cat === "latest") {
      const filteredItems = gridWorks1.apps.nodes.filter((item) => {
        return item.data.Latest
      });
      setActiveLink(cat);
      setItems(filteredItems);
    }
  };

  useEffect(() => {
    filterBy("highlights")
  }, []);


  return (
    <>
      <Section className="position-relative">
        <Container>
          <Box
            mb="2.5rem"
            ml="-1.75rem"
            className="d-flex justify-content-center"
          >
            <ListNav className="nav">
              <li className="nav-item">
                <a role="button"
                  tabIndex={0}
                  className={`nav-link font-weight-bold text-uppercase ${activeLink === "highlights" ? "active" : null
                    }`}
                  onClick={(e) => {
                    e.preventDefault();
                    filterBy("highlights");
                  }}
                >
                  Highlights
              </a>
              </li>
              <li className="nav-item">
                <a role="button"
                  tabIndex={-1}
                  className={`nav-link font-weight-bold text-uppercase ${activeLink === "latest" ? "active" : null
                    }`}
                  onClick={(e) => {
                    e.preventDefault();
                    filterBy("latest");
                  }}
                >
                  Latest Updates
                </a>
              </li>
            </ListNav>
          </Box>
        </Container>
        <Container>
          <Grid>
            {items.map((item, i) => (
              <Col lg="3" md="4" sm="6" key={item.recordId} className="filtr-item">
                <WorkCard className="position-relative" mb="30px">
                  <RotateAppIcon
                    link={'apps/' + item.data.Path}
                    alt={item.data.Name}
                    imgSrc={item.data.Icon[0].thumbnails.large.url}
                  />

                  <Box pt="2.125rem">
                    <WorkText className="overflow-hidden text-center">
                      <Text variant="tag" mb="0.5rem">
                        {item.data.Creator}
                      </Text>
                      <Title variant="cardLg">
                        <Link to={"apps/" + item.data.Path}>{item.data.Name}</Link>
                      </Title>
                    </WorkText>
                  </Box>
                </WorkCard>
              </Col>
            ))}
          </Grid>
          <Box pt="3rem" className="text-center">
            <Link to="apps">
              <ButtonOutline>Show me more apps</ButtonOutline>
            </Link>
          </Box>
        </Container>
      </Section>
    </>
  );
};

export default Apps;
